'use strict';

function addEventAll(querySelectorAll, callback, eventType = "click", useCapture = false) {
    document.querySelectorAll(querySelectorAll)
        .forEach(function (elem) {
            elem.addEventListener(eventType, function (e) {
                callback(e);
            },
                useCapture);
        });
}

function scroll(querySelector, left = false) {
    document.querySelector(querySelector).scrollLeft += (left ? -1 : 1) * (17 * 16);
}

function scrollRight(querySelector) {
    scroll(querySelector, false);
}

function scrollLeft(querySelector) {
    scroll(querySelector, true);
}